<template>
  <header ref="navbarRef" class="h-20 lg:h-25">
    <nav :class="[ 'w-full h-20 lg:h-25 p-4 lg:p-5 top-0 end-0 z-20 transition-transform duration-300 bg-primary-dark', true ? 'is-sticky fixed' : 'fixed lg:absolute' ]">
      <div v-if="ui.displayMobileSearch" class="w-full">
        <Search :label="$t('text-search-label')" variant="big" />
      </div>
      <div v-else class="flex justify-between items-center px-3 lg:px-10 h-full">
        <ShoppingBagBtn class="mr-5 lg:hidden" />
        <Logo class="mx-auto lg:mx-0" variant="color" />
        <MobileBurgerButton class="ml-5 lg:hidden" />
        <div class="hidden lg:block w-full">
          <div class="w-full pl-10 pr-5 overflow-hidden flex flex-row items-center justify-start">
            <Search :label="$t('text-search-label')" variant="big" />
          </div>
        </div>
        <ul class="hidden lg:flex items-center flex-shrink-0">
          <li v-for="{ routeCode, label, minWidth } in links" :key="`${routeCode}_${label}`">
            <NavLink
              v-if="windowSize.width.value >= minWidth"
              :href="translatePath(routeCode)"
              :aria-label="$t(label)"
              active-class="text-accent-dark"
              inactive-class="text-light"
              class="no-underline font-bold flex items-center transition-colors duration-200 hover:text-accent-dark-hover focus:text-accent-dark-hover mx-5"
            >
              <span @click="store.setLoadResetOthers">{{ $t(label) }}</span>
            </NavLink>
          </li>
          <li class="px-5">
            <AddInspirationButton v-if="routeName.startsWith('inspiration___') || routeName.startsWith('inspiration-type-slug___')" />
            <AddAdButton v-else />
          </li>
          <li v-if="!_.find(links, { routeCode: ROUTES.CODE.INSPIRATION })" class="pl-5">
            <InspirationButton />
          </li>
          <li class="pl-5">
            <FavoritesButton />
          </li>
          <li v-if="false" class="pl-5">
            <NotificationsButton />
          </li>
          <li class="pl-5">
            <ShoppingBagBtn />
          </li>
          <li v-if="auth.isLoggedIn" class="pl-5">
            <AuthorizedMenu />
          </li>
          <li v-else class="pl-5">
            <JoinButton />
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script setup>
import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";
import { siteSettings } from "@settings/site.settings";
import { useAuthStore } from '@stores/auth';
import { useSelectedStore } from '@stores/selected';
import { useUIStore } from '@stores/ui';
import Logo from "@components/ui/logo";
import NavLink from "@components/ui/link/nav-link";
import AddAdButton from "@components/layout/navbar/add-ad-button";
import AddInspirationButton from "@components/content/add-inspiration-button";
import AuthorizedMenu from "@components/layout/navbar/authorized-menu";
import JoinButton from "@components/layout/navbar/join-button";
import Search from "@components/common/search";
import ShoppingBagBtn from "@components/cart/shopping-bag-btn";
import FavoritesButton from "@components/layout/navbar/favorites-button";
import NotificationsButton from "@components/layout/navbar/notifications-button";
import InspirationButton from "@components/layout/navbar/inspiration-button";
import MobileBurgerButton from "@components/layout/navbar/mobile-burger-button";

const auth = useAuthStore();
const store = useSelectedStore();
const ui = useUIStore();
const route = useRoute();
const windowSize = useWindowSize();

defineProps({
    links: {
        type: Array,
        default: () => siteSettings.headerLinks
    }
})

const routeName = computed(() => route?.name || '');

</script>
